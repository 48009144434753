import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Book1Img from "../../assets/dashboard/book3.jpg";
import DashboardNavbar from "../DashboardNavbar/DashbaordNavbar";
import donut_dark from "../../assets/dashboard/donut.png";
import donut_dark_bottom from "../../assets/dashboard/Donut-light-big.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaBookReader } from "react-icons/fa";
import DashboardMenu from "../MenuBar/dashboardMenu";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { updateOrInsertBookLanguageStats } from "../../Helpers/statsApiService";
import { toast } from "react-toastify";
import PasswordConfirmation from "../PasswordConfirmation/PasswordConfirmation";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const config = require("../../Helpers/config.json");

const NewDashboard = () => {
  const navigate = useNavigate();
  const readingProgressRef = useRef(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [data, setdata] = useState([]);
  const [Bookdata, setBookdata] = useState([]);
  const [showLangOption, setShowLangOption] = useState(false);
  const [possiblelanguages, setPossiblelanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [discription, setDiscription] = useState("");
  const [hoveredIndex0, setHoveredIndex0] = useState(null);
  const [readingBook, setReadingBook] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const [readingProgressCount, setReadingProgressCount] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [hoverLeave, setHoverLeave] = useState(false);

  const [topSectionData, setTopSectionData] = useState([])
  const [
    showRightArrowForReadingProgress,
    setShowRightArrowForReadingProgress,
  ] = useState(false);

  // console.log("hover leave", hoverLeave);

  const setDesktopViewport = () => {
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    viewportMetaTag.content = "width=1024"; // Set the width to your desired desktop width
  };

  useEffect(() => {
    setDesktopViewport();
    return () => {
      const viewportMetaTag = document.querySelector('meta[name="viewport"]');
      viewportMetaTag.content = "width=device-width, initial-scale=1.0";
    };
  }, []);



  useEffect(() => {
    if ("speechSynthesis" in window) window.speechSynthesis.cancel();
    fetch(`${config["baseUrl"]}/getcategories`, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.message === "fetched") {
          let temp = [];
          temp = res.data;
          setdata([...temp]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  const newData = localStorage.getItem("data");
  const profileID = localStorage.getItem("profileId");
  const formatData = JSON.parse(newData);

  useEffect(() => {
    axios
      .get(
        `${config["baseUrlAws"]}/api/stats/books/getBookReadingProgress/?userId=${formatData?.uid}&profileId=${profileID}`
      )
      .then((res) => {
        if (res && res.data && res.data.data) {
          setReadingBook(res.data.data);
        } else {
          console.error("Invalid response format:", res);
        }
      })
      .catch((e) => console.log(e));
  }, []);


  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const moveContinueSectionToTop = (data) => {
    const continueSectionIndex = data.findIndex(section => section.name === "Continue Reading");
    if (continueSectionIndex !== -1) {
      const [continueSection] = data.splice(continueSectionIndex, 1);
      data.unshift(continueSection);
    }
    return data;
  }

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        setLoading(true);

        // Fetch data from API
        const response = await fetch(
          `${config["baseUrlAws"]}/s3/book/${process.env.REACT_APP_ORGANIZATION_NAME}`,
          {
            method: "GET",
            headers: { "content-type": "application/json" },
          }
        );
        let { data } = await response.json();

        // Check if subscription is active
        const isSubscriptionActive = true;
        let filteredData = [];
        if (!isSubscriptionActive) {
          // Static array of book IDs
          const staticBookIds = [17, 34, 35];
          for (let i = 0; i < data.length; i++) {
            if (staticBookIds.includes(data[i].id)) {
              // console.log(`Book matched: ${data[i].id}`);

              // Add matched book to filteredData
              filteredData.push(data[i]);
            }
          }

          // Log the final filteredData array
          // console.log("Filtered Data:", filteredData);
          // Reassign `data` to the filtered array
          data = filteredData;

        }


        // Map book IDs to their progress percentages
        const bookIdToProgress = readingBook?.reduce((acc, item) => {
          acc[item.book_id] = item.progress_percentage;
          return acc;
        }, {});

        // Cache colors for genres to ensure consistency
        const genreColorMap = {};
        const getGenreColor = (genre) => {
          if (!genreColorMap[genre]) {
            genreColorMap[genre] = getRandomColor();
          }
          return genreColorMap[genre];
        };

        // Group books by genres and handle 'Continue Reading' and 'New Books' sections
        const groupedBooksMap = {};
        const continueReadingBookIds = new Set();
        const sortedData = [...data].sort((a, b) => new Date(b.created_At) - new Date(a.created_At));

        // Identify books that are in progress
        data.forEach((book) => {
          const progress = Number(bookIdToProgress?.[book.id]);
          const isInProgress = progress > 0 && progress < 100;
          const bookWithProgress = bookIdToProgress?.[book.id] !== undefined
            ? { ...book, progress, isProgress: true }
            : book;

          if (isInProgress) {
            if (!groupedBooksMap["Continue Reading"]) {
              groupedBooksMap["Continue Reading"] = {
                name: "Continue Reading",
                items: [],
                bg: "#f57f17",
                matchCount: 0,
              };
            }
            groupedBooksMap["Continue Reading"].items.push(bookWithProgress);
            continueReadingBookIds.add(book.id);
          }
        });

        // Add 'Recently Added' section excluding books in 'Continue Reading'
        const newBooks = sortedData.filter((book) => !continueReadingBookIds.has(book.id)).slice(0, 5).map((book) => ({ ...book, label: "New", labelColor: "red" }));
        const newBookIds = new Set(newBooks.map((book) => book.id));

        if (newBooks.length > 0) {
          groupedBooksMap["Recently Added"] = {
            name: "Newly Added",
            items: newBooks,
            bg: "red",
            matchCount: newBooks.length,
          };
        }

        data.forEach((book) => {
          if (continueReadingBookIds.has(book.id) || newBookIds.has(book.id)) {
            return;
          }

          // Split genres and trim whitespace
          const genres = book.genre.split(",").map((genre) => genre.trim());
          genres.forEach((genre) => {
            if (!groupedBooksMap[genre]) {
              groupedBooksMap[genre] = {
                name: genre,
                items: [],
                bg: getGenreColor(genre),
                matchCount: 0,
              };
            }
            groupedBooksMap[genre].items.push(book);
            if (bookIdToProgress?.[book.id] !== undefined) {
              groupedBooksMap[genre].matchCount++;
            }
          });
        });

        // Build groupedBooks array with 'Recently Added' first, then 'Continue Reading', and then other genres
        const groupedBooks = [];
        if (groupedBooksMap["Recently Added"]) {
          groupedBooks.push(groupedBooksMap["Recently Added"]);
        }
        if (groupedBooksMap["Continue Reading"]) {
          groupedBooks.push(groupedBooksMap["Continue Reading"]);
        }
        Object.keys(groupedBooksMap).forEach((key) => {
          if (key !== "Continue Reading" && key !== "Recently Added") {
            groupedBooks.push(groupedBooksMap[key]);
          }
        });

        // Update state
        setAllBooks(groupedBooks);
        setReadingProgressCount(
          groupedBooks.reduce((acc, genre) => acc + genre.matchCount, 0)
        );
        setTopSectionData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBooks();
  }, [readingBook]);





  const getStoryBookLanguages = async (item) => {
    const languages = item.pdfs.map(pdf => pdf.language)
    setPossiblelanguages(languages);
    setSelectedBook(item)
    setShowLangOption(true);
  };


  const navigateToBookWithId = (lang, bookId) => {
    navigate(`/book-detail?lang=${lang}`, {
      state: { SelectedBook: bookId, isFromNew: true },
    });
    updateOrInsertBookLanguageStats(
      localStorage.getItem("id"),
      localStorage.profileId,
      bookId,
      1,
      lang
    );
  };

  const navigateToBook = (lang) => {
    navigate(`/book-detail?lang=${lang}`, {
      state: { SelectedBook: selectedBook, isFromNew: true },
    });
    updateOrInsertBookLanguageStats(
      localStorage.getItem("id"),
      localStorage.profileId,
      selectedBook.id,
      1,
      lang
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass("fade-out");

      setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          const newIndex = prevIndex < topSectionData.length - 1 ? prevIndex + 1 : 0;
          return newIndex;
        });

        setTimeout(() => {
          setFadeClass("fade-in");
        }, 400);

      }, 200);
    }, 13000);

    return () => clearInterval(interval);
  }, [topSectionData.length]);


  const [arrowDisableLeft, setArrowDisableLeft] = useState({
    initial: true,
    first: true,
    second: true,
    third: true,
    four: true,
  });
  const [arrowDisableRight, setArrowDisableRight] = useState({
    initial: false,
    first: false,
    second: false,
    third: false,
    four: false,
  });

  useEffect(() => {
    const containerWidth = readingProgressRef?.current?.scrollWidth;
    const cardWidth = 336;
    const totalWidth = readingProgressCount * cardWidth;
    setShowRightArrowForReadingProgress(totalWidth > containerWidth);
  }, [readingProgressCount]);

  const scrollRefs = useRef([]);

  const handleScroll = (index, element, speed, distance, step) => {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);

      const isScrollAtEnd =
        element.scrollLeft + element.clientWidth >= element.scrollWidth;

      if (scrollAmount >= distance || isScrollAtEnd) {
        clearInterval(slideTimer);

        // Update arrow states based on current scroll position
        setArrowDisableLeft((prev) => ({
          ...prev,
          [index]: element.scrollLeft === 0,
        }));
        setArrowDisableRight((prev) => ({
          ...prev,
          [index]: isScrollAtEnd,
        }));
      }
    }, speed);
  };

  return (
    <div className="new-dashboard-wrapper">
      {loading && (
        <div className="loader-overlay">
          <div className="loader" />
        </div>
      )}
      <div className="dashbaord-donut-container">
        <img src={donut_dark} className="dashbaord-donut" alt="" />
      </div>
      <DashboardNavbar setShowModal={setShowModal} />
      <img src={donut_dark_bottom} className="dashbaord-donut-bottom" alt="" />
      <div className="content-wrrapers">
        <DashboardMenu setShowModal={setShowModal} />
        <div className="sub-content-wrrapers">
          <div className="cards-text-container">

            {/* top sectionn */}

            <div className="text-img-container">
              <div className="text-button-container">
                <div className="happy-hd-container">
                  <h1 className="happy-reading-hd">Happy Reading,</h1>
                  <h1 className="jamal-hd">{localStorage.getItem("name")}</h1>
                </div>
                <div className="happy-text-container">
                  <p className="happy-text">{topSectionData[currentIndex]?.description}</p>
                </div>
                <div
                  className="read-now-btn-container"
                  onClick={() => {
                    // setSelectedBook(topSectionData[currentIndex]?.id);
                    setSelectedBook(topSectionData[currentIndex]);
                    //console.log("topSectionData[currentIndex]?.id: ",topSectionData[currentIndex]?.id)
                    //navigateToBookWithId("English",topSectionData[currentIndex]?.id);
                  }}
                >


                  {/* <button className="read-now-btn">Read Now</button> */}
                </div>
              </div>
              <div className={`img-container ${fadeClass}`}>
                <div className="dashboard-img-book-cover dashboard-img-book-cover-responsive">

                  <img
                    className="dashboard-img main-dashboard-img-responsive"
                    src={topSectionData[currentIndex]?.book_cover_image}
                    alt=""
                  />
                  <div className="book-spine"></div>
                </div>

                <p className="dashboard-img-hd">
                  {topSectionData[currentIndex]?.title || ""}
                </p>
              </div>
              <div className="happy-text-container-responsive">
                <p className="happy-text-responsive">
                  Reading books is a enriching activity where individuals engage
                  with written content to gain knowledge, entertainment, and
                  insights. It fosters literacy, critical thinking, and
                  imagination, providing a means to explore diverse ideas,
                  perspectives, and experiences. Whether for education or
                  leisure, reading contributes to personal growth and enjoyment.
                </p>
              </div>
              <div className="read-now-btn-container-responsive">
                <button
                  className="read-now-btn-responsive"
                  onClick={() => {
                    // getStoryBookLanguages(topSectionData[currentIndex]?.id);
                    setSelectedBook(topSectionData[currentIndex]);
                  }}
                >
                  Read Now
                </button>
              </div>
            </div>




            <div className="card-main-wraps">
              {allBooks.length === 0 ? (
                <p className="no-data-hd">No Books Found!</p>
              ) : (
                allBooks.map((book, bookIndex) => (
                  <div className="continue-reading-container" style={{ position: 'relative' }} key={bookIndex}>
                    <div className="reading-hd-container">
                      <h3 className="continue-reading-hd">{book.name}</h3>
                    </div>
                    <div
                      className="all-cards"
                      ref={(el) => (scrollRefs.current[bookIndex] = el)}
                      style={{ display: 'flex', flexWrap: 'wrap' }} // Ensure this styling is present
                    >
                      {book?.items?.map((item, itemIndex) => {
                        const uniqueKey = `${bookIndex}-${itemIndex}`; // Create a unique key
                        return (
                          <div ref={readingProgressRef} key={itemIndex}>
                            <div
                              className="card-container"
                              onClick={() => getStoryBookLanguages(item)}
                              onMouseEnter={() => {
                                setHoveredIndex0(uniqueKey);
                                setHoverLeave(false);
                              }}
                              onMouseLeave={() => {
                                setHoveredIndex0(null);
                              }}
                            >
                              <div className="img-logo-container">
                                <p className="img-logo" style={{ background: book.bg }}>
                                  {book?.name}
                                </p>
                              </div>
                              <div className="book-cover">
                                <img
                                  src={item?.book_cover_image}
                                  alt=""
                                  className="card-img"
                                />
                                <div className="book-spine"></div>
                              </div>
                              <ProgressBar
                                variant="warning"
                                now={item?.progress}
                                style={{ height: "8px", marginTop: "15px" }}
                              />
                              <p className="dashboard-img-hd"></p>

                              {hoveredIndex0 === uniqueKey && (
                                <div
                                  className={`highlighted-card-container ${hoverLeave ? "hover-leave" : ""}`}
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top: "-10px",
                                    left: itemIndex === 0 ? "0px" : itemIndex === book.items.length - 1 ? "-85px" : "-30px",
                                    zIndex: 5,
                                    transition: "all 0.1s ease-in-out",
                                  }}
                                  onClick={() => {
                                    setSelectedBook(item);
                                  }}
                                >
                                  <div className="img-logo-container">
                                    <p className="img-logo" style={{ background: book.bg }}>
                                      {book.name}
                                    </p>
                                  </div>
                                  <div className="highlighted-book-cover">
                                    <img
                                      src={item.book_cover_image}
                                      alt=""
                                      className="card-img"
                                    />
                                    <div className="book-spine"></div>
                                  </div>
                                  <div className="highlighted-book-content">
                                    <div className="highlighted-icons">
                                      <div className="highlighted-icons-flex-1">
                                        <div
                                          className="card-btn-icon-wrap"
                                          onClick={() => {
                                            setSelectedBook(item);
                                          }}
                                        >
                                          <div className="highlighted-icon">
                                            <FaBookReader style={{ fontSize: "12px", color: "white" }} />
                                          </div>
                                          <div>
                                            <p className="card-read-now-btn">Read Now</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="highlighted-icons-flex-2"></div>
                                    </div>
                                    <p className="highlighted-dashboard-img-hd">{item?.title}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="language-modal">
              {showLangOption && (
                <Modal
                  show={showLangOption}
                  onHide={() => setShowLangOption(false)}
                  animation={false}
                  size="md"
                  centered
                >
                  <Modal.Header
                    closeButton
                    className="lang-modal-header"
                  >
                    <Modal.Title>
                      Please Select a language of book
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="lang-modal-body">
                    <div className="all-btns-wrap">
                      {possiblelanguages.length > 0 &&
                        possiblelanguages?.map((lang) => {
                          return (
                            <Button
                              className="all-btns"
                              variant="warning"
                              onClick={() => navigateToBook(lang)}
                            >
                              {lang?.charAt(0)?.toUpperCase() +
                                lang?.slice(1)}
                            </Button>
                          );
                        })}
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        <PasswordConfirmation
          showModal={showModal}
          setShowModal={setShowModal}
          route={"/account"}
        />
      }
      <div>

      </div>
    </div>
  );
};

export default NewDashboard;
